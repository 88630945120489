import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Emitter from './Emitter';
import Logger from './Logger';

export default class API {
    static URLCache = {};
    static analyticCache = {};

    constructor() {
        this.sfApiUrl = process.env.REACT_APP_API_URL;
        this.signedUrls = [];
        this.smartApiUrl = process.env.REACT_APP_SMART_API;
        this.logger = new Logger();
    }

    async exchangeToken(idToken) {
        let correlationId = uuidv4();
        return axios.get(`${this.sfApiUrl}/api/auth/token-exchange`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
                'smt-correlation-id': correlationId,
                'smart-token': 'true',
            },
        });
    }

    async getSignedUrl(uuid) {
        let correlationId = uuidv4();

        this.logger.log('Checking URL Cache', null, { skipLocalLogging: true });

        if (API.getCachedUrl(this.logger, uuid)) {
            this.logger.log('Found URL in cache', null, {
                skipLocalLogging: true,
            });
            return API.URLCache[uuid];
        } else {
            let authToken = window.localStorage.getItem('authToken');

            this.logger.log('Fetching signed url', null, {
                skipLocalLogging: true,
            });

            return axios
                .get(`${this.sfApiUrl}/api/sign/${uuid}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'smt-correlation-id': correlationId,
                    },
                })
                .then((result) => {
                    API.setCachedUrl(result);
                    return result;
                })
                .catch((error) => {
                    this.logger.error(
                        'Error fetching signed URL for requested media',
                        error,
                    );
                    Emitter.emit('LINK_ERROR', {
                        message:
                            'Error fetching signed URL for requested media',
                        skipLog: true,
                        uuid,
                    });
                    return null;
                });
        }
    }

    async getAnalytic(uuid) {
        this.logger.log(`Getting analytic for ${uuid}`, null, {
            skipLocalLogging: true,
        });

        const cachedAnalytic = API.getCachedAnalytic(this.logger, uuid);
        if (cachedAnalytic) {
            return cachedAnalytic;
        }

        let authToken = window.localStorage.getItem('authToken');

        const url = `${this.smartApiUrl}/lockbox/analytic/v1/adt-res/objects/${uuid}`;
        return axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    includeFrame: 'true',
                },
            })
            .then((result) => {
                const analytic = result.data;
                API.setCachedAnalytic(analytic);
                return analytic;
            })
            .catch((error) => {
                this.logger.error(
                    `Error fetching analytic for uuid ${uuid}`,
                    error.stack,
                );
            });
    }

    static getCachedUrl(logger, uuid) {
        if (API.URLCache.hasOwnProperty(uuid)) {
            let cachedObject = API.URLCache[uuid];
            if (API.isExpired(cachedObject.data.exp)) {
                logger.log('Signed url found but expired', null, {
                    skipLocalLogging: true,
                });
                delete API.URLCache[uuid];
                return false;
            } else {
                logger.log('Signed url found.', null, {
                    skipLocalLogging: true,
                });
                return cachedObject;
            }
        } else {
            return false;
        }
    }

    static setCachedUrl(urlData) {
        API.URLCache[urlData.data.uuid] = urlData;
    }

    static getCachedAnalytic(logger, uuid) {
        if (!API.analyticCache.hasOwnProperty(uuid)) {
            return false;
        }

        const cachedObject = API.analyticCache[uuid];
        logger.log(`Analytic found for ${uuid} in cache`, null, {
            skipLocalLogging: true,
        });
        return cachedObject;
    }

    static setCachedAnalytic(analytic) {
        API.analyticCache[analytic.objectId] = analytic;
    }

    static isExpired(exp) {
        let now = new Date();
        let expired = new Date(exp);
        return now > expired;
    }
}
