import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { theme } from '../theme/theme';

const CustomTypographyRow = ({ label, value }) => {
    return (
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={4}>
                <Typography
                    style={{
                        marginRight: '5px',
                        color: theme.palette.common.grey[700],
                        fontWeight: 'bold',
                        fontSize: '0.75em',
                    }}
                >
                    {label}:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        fontSize: '0.75em',
                    }}
                >
                    {value ?? 'Unavailable'}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CustomTypographyRow;
