import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import jwt from 'jwt-decode';

import API from './API';
import Emitter from './Emitter';
import Logger from './Logger';

export default class Auth {
    constructor() {
        this.handleLogin = this.handleLogin.bind(this);
        this.checkExpiration = this.checkExpiration.bind(this);
        this.interval = null;
        this.api = new API();
        this.logger = new Logger();
        this.tenantId = process.env.REACT_APP_AZURE_TENANT_ID;
        this.domainHint = process.env.REACT_APP_AZURE_DOMAIN_HINT;
        this.firebase = !firebase.apps.length
            ? firebase.initializeApp({
                  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
              })
            : firebase.app();
    }

    checkExpiration() {
        const authExpiration = window.localStorage.getItem('tokenExpiration');

        if (authExpiration === null) {
            return;
        }

        const exp = new Date(authExpiration * 1000);
        const now = new Date();
        const timeout = exp.getTime() - now.getTime();

        if (timeout <= 0) {
            clearInterval(this.interval);
            this.logout();
        }
    }

    handleLoginAttempt(result, callback = null) {
        if (callback) {
            callback(null, result);
        }

        this.handleLogin(result);
    }

    handleLoginError(error, callback = null) {
        const errorCode = error.code;
        const errorMessage = error.message;

        let userMessage;

        switch (errorCode) {
            case 'auth/invalid-credential':
                userMessage =
                    'Your username is not part of a valid Active Directory group.\nYou will be unable to see live or recorded video from any of the active alarms.\nPlease reach out to your Supervisor so that your credentials can be added to a valid AD group.';
                break;
            case 'auth/network-request-failed':
                userMessage =
                    'Network request failed.\nPlease check your internet connection and try again.';
                break;
            case 'auth/user-not-found':
                userMessage =
                    'User not found.\nPlease check your credentials and try again.';
                break;
            case 'auth/wrong-password':
                userMessage = 'Incorrect password.\nPlease try again.';
                break;
            case 'auth/popup-closed-by-user':
                userMessage =
                    'The popup was closed before completing the sign-in.\nPlease try again.';
                break;
            case 'auth/cancelled-popup-request':
                userMessage =
                    'This operation was canceled due to another conflicting popup being opened.';
                break;
            case 'auth/operation-not-allowed':
                userMessage =
                    'This operation is not allowed.\nPlease contact support.';
                break;
            case 'auth/too-many-requests':
                userMessage = 'Too many requests.\nPlease try again later.';
                break;
            default:
                userMessage =
                    'Unexpected authentication error.\nPlease try again or contact support if the problem persists.';
                break;
        }

        Emitter.emit('APP_ERROR', {
            message: userMessage,
        });

        this.logger.error(`Authentication error ${errorCode}: ${errorMessage}`);

        if (callback) {
            callback(error, null);
        }
    }

    popupLogin(callback) {
        this.callback = callback;

        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        const auth = firebase.auth();

        provider.setCustomParameters({
            tenant: this.tenantId,
            domain_hint: this.domainHint,
        });
        provider.addScope('User.Read');

        auth.signInWithPopup(provider)
            .then((result) => {
                this.handleLoginAttempt(result, callback);
            })
            .catch((error) => {
                this.handleLoginError(error, callback);
            });
    }

    async handleLogin(userData) {
        if (typeof userData === 'object' && userData.credential) {
            let authTokenData;

            try {
                if (!userData.credential.idToken) {
                    Emitter.emit('APP_ERROR', {
                        message:
                            'There was a problem with Firebase authentication. No user idToken received.',
                    });
                    return;
                }

                authTokenData = await this.api.exchangeToken(
                    userData.credential.idToken,
                );
            } catch (err) {
                if (err?.response?.status === 401) {
                    Emitter.emit('APP_ERROR', {
                        message:
                            'Your username is not part of a valid Active Directory group. You will be unable to see live or recorded video from any of the active alarms. Please reach out to your Supervisor so that your credentials can be added to a valid AD group.',
                    });
                    return;
                } else {
                    Emitter.emit('APP_ERROR', {
                        message:
                            'There was an issue generating a security token: ' +
                            err?.response?.status +
                            (err?.response?.data?.message ?? ''),
                    });
                    return;
                }
            }

            if (!authTokenData.data.token) {
                Emitter.emit('APP_ERROR', {
                    message: 'There was an issue generating a security token.',
                });
                return;
            }

            Emitter.emit('APP_MESSAGE', {
                message: 'Now loading...',
            });

            const authToken = authTokenData.data.token;
            const jwtUser = jwt(authToken);
            const now = new Date();
            const expiration = new Date(jwtUser.exp * 1000);
            const timeout = expiration.getTime() - now.getTime();

            window.localStorage.setItem('authToken', authToken);
            window.localStorage.setItem('tokenExpiration', jwtUser.exp);

            this.interval = setInterval(this.checkExpiration, timeout);
            Emitter.emit('FIREBASE_LOGIN_FINISH', userData);
            Emitter.emit('APP_MESSAGE', {
                message: '',
            });
        }
    }

    logout() {
        firebase.auth().signOut();
        window.localStorage.clear();
        Emitter.emit('APP_ERROR', {
            message: null,
        });
        Emitter.emit('USER_LOGOUT');
    }
}
