import { createTheme } from '@mui/material/styles';

const createGreyPalette = (defaultGrey) => {
    const grey = {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#E7EAEC',
        300: '#D4D9DD',
        400: '#B7BDC0',
        500: '#898E91',
        600: '#6B7073',
        700: '#46494B',
        800: '#313435',
        900: '#1D1E20',
        default: defaultGrey,
    };

    return new Proxy(grey, {
        get(target, prop) {
            if (target[prop]) {
                return target[prop];
            }
            return target.default;
        },
    });
};

export const hexToRGBA = (hex, opacity = 1) => {
    let r = 0,
        g = 0,
        b = 0;
    if (hex.length === 4) {
        r = '0x' + hex[1] + hex[1];
        g = '0x' + hex[2] + hex[2];
        b = '0x' + hex[3] + hex[3];
    } else if (hex.length === 7) {
        r = '0x' + hex[1] + hex[2];
        g = '0x' + hex[3] + hex[4];
        b = '0x' + hex[5] + hex[6];
    }

    return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + opacity + ')';
};

export const theme = createTheme({
    typography: {
        fontFamily:
            'Open Sans Condensed, Montserrat, Roboto, Arial, sans-serif',
    },
    palette: {
        common: {
            white: '#FFFFFF',
            black: '#000000',
            grey: createGreyPalette('#46494B'),
        },
        primary: {
            main: '#0061AA',
            hover: '#007EDD',
        },
        secondary: {
            main: '#1F99E0',
            hover: '#1F7CBA',
        },
        error: {
            main: '#FF0000',
            hover: '#1F7CBA',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                fontFamily:
                    'Open Sans Condensed, Montserrat, Roboto, Arial, sans-serif',
            },
        },
        MuiLink: {
            defaultProps: {
                fontFamily:
                    'Open Sans Condensed, Montserrat, Roboto, Arial, sans-serif',
            },
            styleOverrides: {
                root: {
                    fontFamily:
                        'Open Sans Condensed, Montserrat, Roboto, Arial, sans-serif',
                },
            },
        },
    },
});
