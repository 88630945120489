import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';
import HeaderItem from './HeaderItem';
import { ReactComponent as ADTSmartIcon } from '../assets/adtsmart.svg';

const HeaderBar = (props) => {
    const eventData = props.eventData;
    const date = new Date(eventData.too);

    const eventTime =
        date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        }) +
        ' ' +
        date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });

    return (
        <Grid
            container
            spacing={2}
            sx={{
                paddingBottom: '1rem',
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Grid item xs={12}>
                        <SvgIcon
                            component={ADTSmartIcon}
                            inheritViewBox
                            sx={{
                                width: 'auto',
                                height: '100%',
                                maxWidth: '425px',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        sx={{ order: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 } }}
                    >
                        <HeaderItem label="CS#" value={eventData.csId} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        sx={{ order: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 } }}
                    >
                        <HeaderItem label="Zone" value={eventData.zone} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ order: { xs: 6, sm: 6, md: 6, lg: 3, xl: 3 } }}
                    >
                        <HeaderItem
                            label="Event ID"
                            value={eventData.alarmId}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        sx={{ order: { xs: 3, sm: 3, md: 3, lg: 4, xl: 4 } }}
                    >
                        <HeaderItem label="Site" value={eventData.siteId} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        sx={{ order: { xs: 4, sm: 4, md: 4, lg: 5, xl: 5 } }}
                    >
                        <HeaderItem
                            label="Location"
                            value={eventData.locationName}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ order: { xs: 5, sm: 5, md: 5, lg: 6, xl: 6 } }}
                    >
                        <HeaderItem label="Event Time" value={eventTime} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HeaderBar;
